<template>
    <div class="content">
        <div class="top_view" v-if="isOver">
            <div class="record_over_view">
                <div class="record_title">
                    空调清洗秒杀名额已满，预约通道已经关闭，感谢大家的参与支持！
                </div>
            </div>
        </div>
        <div class="top_view" v-else>
            <div class="record_title_view">
                <div class="record_title">
                    海阔天空空调团购登记  
                </div>
            </div>
            <div class="inviteCount">
                <van-field
                    v-model="form.name"
                    name="address"
                    label="01:姓名"
                    required
                    placeholder="请输入您的姓名"
                    />

            </div>
            <div class="inviteCount">
                <van-field
                    v-model="form.phone"
                    name="address"
                    label="02:电话"
                    type="number"
                    required
                    placeholder="请输入您的电话"
                    />
            </div>
            <div class="inviteCount">
                <van-field
                    v-model="form.address"
                    name="address"
                    label="03:详细地址"
                    required
                    autosize
                    type="textarea"
                    placeholder="请输入详细地址"
                    />
            </div>
            <div class="inviteCount">
                <van-field
                    v-model="form.serviceTime"
                    name="serviceTime"
                    label="04:服务时间"
                    required
                    placeholder="请选择服务时间"
                    readonly
                    clickable
                    @click="showPicker = true"
                    />
            </div>
            <div class="inviteCount">
                <van-field
                    v-model="form.serviceCount"
                    name="serviceCount"
                    label="05:服务数量"
                    required
                    placeholder="请选择服务数量"
                    readonly
                    clickable
                    @click="showCountPicker = true"
                    />
            </div>

            <div class="invite_sure" @click="getData"> 提交 </div>
        </div>


        <van-popup v-model="showPicker" round position="bottom">
            <van-picker
            :title="projectNameValue"
            show-toolbar
            :columns="projectListArr"
            @cancel="showPicker = false"
            @confirm="changeConfirm"
            />
        </van-popup>

        <van-popup v-model="showCountPicker" round position="bottom">
            <van-picker
            :title="projectCountValue"
            show-toolbar
            :columns="projectCountListArr"
            @cancel="showCountPicker = false"
            @confirm="changeCountConfirm"
            />
        </van-popup>
        
    </div>
</template>

<!-- JS交互 -->
<script>
export default {
  components: {},
  data() {
    return {
        projectNameValue:'请选择服务时间', //input框内显示的值
        projectListArr: ['星期四(7月6日)', '星期五(7月7日)', '星期六(7月8日)', '星期天(7月9日)'], //下拉的数据源，从接口中请求到数据，根据需要变成一维数组，只存name
        appointmentTimeList:[ "2023-07-06", "2023-07-07", "2023-07-08", "2023-07-09"],
        showPicker:false,  //弹出层显示隐藏

        projectCountValue:'请选择服务数量', //input框内显示的值
        projectCountListArr: ['2台', '3台', '4台', '5台', '6台', '7台', '8台', '9台', '10台', '11台', '12台', '13台', '14台', '15台'], //下拉的数据源，从接口中请求到数据，根据需要变成一维数组，只存name
        numberList:[2,3,4,5,6,7,8,9,10,11,12,13,14,15],
        showCountPicker:false,  //弹出层显示隐藏
   
        isOver:false,
        form: {
            phone: "",
            name: "",
            address:"",
            serviceTime:"",
            serviceCount:""
        },

        appointmentTime: "",
        number:0,

    };
  },
  methods: {
    loadRegistrationTotal(){
        this.$axios({
            url: this.$requests.member.getRegistrationTotal,
            method: "post",
            data: {
                code:2,
            },
        }).then((res) => {
            if (res.data.code !== 200) {
                let message =  res.data.message || "请求失败";
                this.$dialog.alert({
                    title: "错误提示",
                    message: message,
                });
            } else{
                if(res.data.data > 100) {
                    this.isOver = true
                }
            }
        }).catch((e) => {});
    },
    getData(){
        let name = this.form.name;
        let phone = this.form.phone;
        let address = this.form.address;
        let serviceTime = this.form.serviceTime;
        let serviceCount = this.form.serviceCount;
        if (name == "") {
            this.$dialog.alert({
                title: "错误提示",
                message: "输入姓名",
            });
            return;
        }
        if (phone == "") {
            this.$dialog.alert({
                title: "错误提示",
                message: "输入手机号",
            });
            return;
        }
        if (address == "") {
            this.$dialog.alert({
                title: "错误提示",
                message: "输入详情地址",
            });
            return;
        }
        if (serviceTime == "") {
            this.$dialog.alert({
                title: "错误提示",
                message: "选择服务时间",
            });
            return;
        }

        if (serviceCount == "") {
            this.$dialog.alert({
                title: "错误提示",
                message: "选择服务数量",
            });
            return;
        }
        if (!this.validatePhone(phone)) {
            this.$dialog.alert({
                title: "错误提示",
                message: "请输入正确的手机号",
            });
            return;
        }

        this.$axios({
            url: this.$requests.member.addRegistration,
            method: "post",
            data: {
                code:2,
                realName: this.form.name,
                phone: this.form.phone,
                address:this.form.address,
                appointmentTime:this.appointmentTime,
                number:this.number
            },
        }).then((res) => {
            if (res.data.code !== 200) {
            let message =  res.data.message || "请求失败";
            this.$dialog.alert({
                title: "错误提示",
                message: message,
            });
            } else{
                this.$dialog.alert({
                title: "团购登记信息提交成功",
                message: "感谢您的信赖，我们将尽快与您联系",
            });
            }
        }).catch((e) => {});
    },
    validatePhone(val) {
        let reg = /^[1](([3][0-9])|([4][0,1,4-9])|([5][0-3,5-9])|([6][2,5,6,7])|([7][0-8])|([8][0-9])|([9][0-3,5-9]))[0-9]{8}$/;
        return reg.test(val);
    },
    changeConfirm(val,index){
        this.form.serviceTime = val;  //传值
        this.appointmentTime = this.appointmentTimeList[index]
        this.showPicker = false;
    },
    changeCountConfirm(val,index){
        this.form.serviceCount = val;  //传值
        this.number = this.numberList[index]
        this.showCountPicker = false;
    },
  },
  mounted() {
    this.loadRegistrationTotal()
  },
};
</script>


<!-- class 展示属性 -->
<style lang="less" scoped>
.content {
    background: #F1F5F6;
    width: 100%;
    min-height: 100%;
    padding: 5vw;
}

.top_view{
    display: flex;
    position: relative;
    flex-direction: column;
}

.record_over_view{
    background: white;
    border-radius: 7px;
    width: 90vw;
    height: 90vh;
    padding: 20px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.inviteCount {
    background: white;
    border-radius: 7px;
    width: 90vw;
    padding: 20px;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.record_title_view{
    background: white;
    border-radius: 7px;
    width: 90vw;
    margin-top: 15px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.record_title{
    text-align: center;
    font-size: larger;
    font-weight: bolder;
    color: #333;
}

.inviteCount::v-deep .van-field__value{
  border: 1px solid #ccc;
  margin-top: 10px;
  padding: 10px;
}

.inviteCount::v-deep .van-cell{
  display: flow-root;
}


.van-cell{
  ::v-deep .van-cell__title {
        font-size: larger;
        font-weight: bold;
        color: #141C23;
    }
}

.invite_sure{
    margin-top: 20px;
    width: 100%;
    height: 40px;
    background: #EE4908;
    border: 0.5px solid #A67D1A;
    border-radius: 10px;
    font-size: 10px;
    font-weight: 500;
    color: #FFFFFE;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

</style>